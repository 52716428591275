import { CalibrationType } from "@/shared/enums/CalibrationType";
import { MarkerType } from "@/shared/enums/MarkerType";
import { SessionType } from "@/shared/enums/SessionType";

export class GlobalData {

    public MainApp: any = null!;

    // Session information

    public CalibrationSessionGuid: string = "";
    public CompanionGuid: string = "";
    public DeviceName: string = "";
    public AllowAssistedCalibration: boolean = false;
    public AllowAssistedTargetDisplay: boolean = false;
    public AllowSelfCalibration: boolean = false;
    public VerifiedVehiclesOnlyInd: boolean = true;
    public SessionType: SessionType|null = null;
    public Make: string = "";
    public Model: string = "";
    public Year: number = 0;
    public Calibration: CalibrationType = CalibrationType.All;
    public LegLengthA: number = 0;
    public LegLengthB: number = 0;
    public LegLengthC: number = 0;
    public Marker: MarkerType = MarkerType.Standard;
    public VehicleId: number|null = null;
    public IsTechControlled: boolean|null = null!;
    public TechnicianName: string|null = null;
    public CameraPositionRejected: boolean = false;
    public LightIntensity: number = 50;
    public WheelArchHeightMin: number|null = null;
    public WheelArchHeightMax: number|null = null;
}
export const Global = new GlobalData();
