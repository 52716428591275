/* eslint-disable @typescript-eslint/explicit-function-return-type */

// https://router.vuejs.org/

import { createRouter, createWebHistory, RouteRecordRaw, Router, RouteLocationNormalized, NavigationFailure } from "vue-router";

import Home from "@/views/Home.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import { SignalR } from "./support/SignalR";

// for lazy loading see https://vuedose.tips/dynamic-imports-in-vue-js-for-better-performance

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/NoConnection",
        name: "NoConnection",
        component: () => import("@/views/NoConnection.vue")
    },
    {
        path: "/Canceled",
        name: "Canceled",
        component: () => import("@/views/Canceled.vue")
    },
    {
        path: "/Vehicle",
        name: "Vehicle",
        component: () => import("@/views/connection/Vehicle.vue")
    },
    {
        path: "/UnverifiedVehicle",
        name: "UnverifiedVehicle",
        component: () => import("@/views/connection/UnverifiedVehicle.vue")
    },
    {
        path: "/Vin",
        name: "Vin",
        component: () => import("@/views/connection/Vin.vue")
    },
    {
        path: "/RideHeight",
        name: "RideHeight",
        component: () => import("@/views/connection/RideHeight.vue")
    },
    {
        path: "/SelectCalibration",
        name: "SelectCalibration",
        component: () => import("@/views/connection/SelectCalibration.vue")
    },
    {
        path: "/CameraSetup",
        name: "CameraSetup",
        component: () => import("@/views/connection/CameraSetup.vue")
    },
    {
        path: "/CameraReady",
        name: "CameraReady",
        component: () => import("@/views/connection/CameraReady.vue")
    },
    {
        path: "/CameraVerification",
        name: "CameraVerification",
        component: () => import("@/views/connection/CameraVerification.vue")
    },
    {
        path: "/CameraImage",
        name: "CameraImage",
        component: () => import("@/views/connection/CameraImage.vue")
    },
    {
        path: "/SendTargets",
        name: "SendTargets",
        component: () => import("@/views/connection/SendTargets.vue")
    },
    {
        path: "/MeasuredWindshieldAngle",
        name: "MeasuredWindshieldAngle",
        component: () => import("@/views/connection/MeasuredWindshieldAngle.vue")
    },
    {
        path: "/NewWindshieldAngle",
        name: "NewWindshieldAngle",
        component: () => import("@/views/connection/NewWindshieldAngle.vue")
    },
    {
        path: "/ContactSupportWindshieldAngle",
        name: "ContactSupportWindshieldAngle",
        component: () => import("@/views/connection/ContactSupportWindshieldAngle.vue")
    },
    {
        path: "/Failed",
        name: "Failed",
        component: () => import("@/views/connection/Failed.vue")
    },
    {
        path: "/Contacting",
        name: "Contacting",
        component: () => import("@/views/connection/Contacting.vue")
    },
    {
        path: "/InProgress",
        name: "InProgress",
        component: () => import("@/views/connection/InProgress.vue")
    },
    {
        path: "/Completed",
        name: "Completed",
        component: () => import("@/views/connection/Completed.vue")
    },
    {
        path: "/Ended",
        name: "Ended",
        component: () => import("@/views/Ended.vue")
    },
    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
    }
];

const router: Router = createRouter({
    history: createWebHistory(),
    routes
});


router.afterEach( (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void): any => {
    if (failure) return;
    if (!SignalR.isConnected) return;
    SignalR.connection.send("PageNavigation", to.path);
});

export default router;

