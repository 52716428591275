
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import App from "@/App.vue";

@Options({
    HeaderMessage
})
export default class Vin extends PageBase {

    nextClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.UnverifiedVehicleContinueByCompanion);
        this.loading = true;
    }
    cancelClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.UnverifiedVehicleBackByCompanion);
        this.loading = true;
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}
