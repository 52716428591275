
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import * as signalR from "@microsoft/signalr";
import { Global } from "./GlobalData";
import Toast, { FeedbackFormat, ToastFeedback } from "@/shared/support/Toast";
import Utility from "@/shared/support/Utility";

export class SignalRSupport {

    // hub messages
    public static readonly CurrentDeviceStatus: string = "CurrentDeviceStatus";
    public static readonly CompanionRejected = "CompanionRejected";
    public static readonly VehicleTargetImageReady = "VehicleTargetImageReady";
    public static readonly TechnicianConnectedSession = "TechnicianConnectedSession";
    public static readonly TechnicianCameraRejected = "TechnicianCameraRejected";
    public static readonly CalibrationCanceledByDevice: string  = "CalibrationCanceledByDevice";
    public static readonly CalibrationDisconnectedDevice: string  = "CalibrationDisconnectedDevice";
    public static readonly DeviceOutOfSync: string  = "DeviceOutOfSync";
    public static readonly DeviceFailure: string  = "DeviceFailure";

    domain: string | undefined = undefined;

    public get connection(): signalR.HubConnection {
        if (!this.connectionCache) throw new Error("Attempted use of disconnected SignalR connection");
        return this.connectionCache;
    }
    private connectionCache: signalR.HubConnection|null = null;

    public get isConnected(): boolean {
        return this.isConnectedCache;
    }
    isConnectedCache = false;
    connecting = false;

    public onVehicleTargetImageReady: ((targetImageUploadId: number, targetNumber: number) => void) | null = null;
    public onTechnicianCameraRejected: (() => void) | null = null;
    public onDeviceCameraFailed: (() => void) | null = null;

    public start(domain?: string): void {

        // Note that the domain has to be the URL of the FUNCTION, not Azure SignalR
        // The initial Negotiate call (to the Function) establishes the actual SignalR URL
        // see https://docs.microsoft.com/en-us/azure/azure-signalr/signalr-concept-serverless-development-config#sending-messages-from-a-client-to-the-service

        if (this.isConnected || this.connecting) return;
        if (domain)
            this.domain = domain;

        this.connecting = true;
        this.connectionCache = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Error)
            .withAutomaticReconnect()
            .withUrl(Utility.formatUrl("", { }, this.domain))
            .build();
        this.bindConnection();
        this.connectionCache.start()
            .then((): void => {
                this.connecting = false;
                this.onConnected();
            })
            .catch((error): void => {
                this.connecting = false;
                this.connectionCache = null;
                console.error(error?.message || "SignalR connection failed");
            });
    }


    private clientPingInterval = 0;

    setupClientPing(): void {
        if (this.clientPingInterval) return;
        const pingInterval = Number(process.env.VUE_APP_CLIENTPINGFREQUENCY) * 1000;
        if (isNaN(pingInterval)) throw new Error("SignalR: VUE_APP_CLIENTPINGFREQUENCY not defined in .env");
        console.debug(`PING ==> ${pingInterval}`);
        this.clientPingInterval = setInterval((): void => {
            if (!this.isConnected) return;
            console.debug(`SignalR: Client Ping ${window.location.pathname}`);
            this.connection.send("PageNavigation", window.location.pathname);
        }, pingInterval) as unknown as number;
    }

    bindConnection(): void {
        this.connection.onclose((error: Error | undefined): void => this.onConnectionError(error));

        this.connection.on(SignalRSupport.CurrentDeviceStatus, (data: string|null): void => {

            if (process.env.VUE_APP_DEBUG === "1")
                console.debug(data);

            if (!data) return;
            const deviceStatus: DeviceStatusDto = JSON.parse(data);
            const page = deviceStatus.Page;

            Global.DeviceName = deviceStatus.DeviceName;
            Global.MainApp.updateHeader();// force header update for device name
            Global.AllowAssistedCalibration = deviceStatus.AllowAssistedCalibration;
            Global.AllowAssistedTargetDisplay = deviceStatus.AllowAssistedTargetDisplay;
            Global.AllowSelfCalibration = deviceStatus.AllowSelfCalibration;
            Global.VerifiedVehiclesOnlyInd = deviceStatus.VerifiedVehiclesOnlyInd;
            Global.SessionType = deviceStatus.SessionType;
            Global.Make = deviceStatus.Make!;
            Global.Model = deviceStatus.Model!;
            Global.Year = deviceStatus.Year!;
            Global.Calibration = deviceStatus.CalibrationType!;
            Global.LegLengthA = deviceStatus.LegLengthA!;
            Global.LegLengthB = deviceStatus.LegLengthB!;
            Global.LegLengthC = deviceStatus.LegLengthC!;
            Global.Marker = deviceStatus.Marker!;
            Global.TechnicianName = deviceStatus.TechnicianName;
            Global.VehicleId = deviceStatus.VehicleId;
            Global.IsTechControlled = deviceStatus.IsTechControlled!;
            Global.WheelArchHeightMin = deviceStatus.WheelArchHeightMin;
            Global.WheelArchHeightMax = deviceStatus.WheelArchHeightMax;

            switch (page) {
                case "Home":
                    Global.MainApp.routerPush("/SelectCalibration");
                    break;
                case "CalibrationVehicle":
                    Global.MainApp.routerPush("/Vehicle");
                    break;
                case "CalibrationUnverifiedVehicle":
                    Global.MainApp.routerPush("/UnverifiedVehicle");
                    break;
                case "CalibrationVin":
                    Global.MainApp.routerPush("/Vin");
                    break;
                case "CalibrationRideHeight":
                    Global.MainApp.routerPush("/RideHeight");
                    break;
                case "CalibrationCameraSetup":
                    Global.MainApp.routerPush("/CameraSetup");
                    break;
                case "CalibrationCameraReady":
                    if (deviceStatus.CameraVerifying)
                        Global.MainApp.routerPush("/CameraVerification");
                    else {
                        Global.MainApp.routerPush("/CameraReady");
                        if (deviceStatus.YawRollValid === false) {
                            if (this.onDeviceCameraFailed)
                                this.onDeviceCameraFailed();
                        }
                    }
                    break;
                case "CalibrationCameraImage":
                    Global.MainApp.routerPush("/CameraImage");
                    break;
                case "CalibrationSendTargets":
                    Global.MainApp.routerPush("/SendTargets", {
                        TargetNumber: deviceStatus.TargetNumber,
                        TotalTargets: deviceStatus.TotalTargets,
                        TargetUploadId: deviceStatus.TargetUploadId,
                    });
                    break;
                case "CalibrationContacting":
                    Global.MainApp.routerPush("/Contacting");
                    break;
                case "CalibrationInProgress":
                    Global.MainApp.routerPush("/InProgress");
                    break;
                case "CalibrationComplete":
                    Global.MainApp.routerPush("/Completed");
                    break;
                case "CalibrationFailed":
                    Global.MainApp.routerPush("/Failed");
                    break;
                case "CalibrationEnded":
                    Global.MainApp.routerPush("/Ended");
                    break;
                case "CalibrationMeasuredWindshieldAngle":
                    Global.MainApp.routerPush("/MeasuredWindshieldAngle", { WindshieldAngle: deviceStatus.WindshieldAngle });
                    break;
                case "CalibrationNewWindshieldAngle":
                    Global.MainApp.routerPush("NewWindshieldAngle");
                    break;
                case "CalibrationContactSupportWindshieldAngle":
                    Global.MainApp.routerPush("ContactSupportWindshieldAngle");
                    break;
            }
        });
        this.connection.on(SignalRSupport.CompanionRejected, (message: string): void => {
            console.error(`${SignalRSupport.CompanionRejected} ${message}`);
            Global.MainApp.routerPush("/NoConnection");
        });
        this.connection.on(SignalRSupport.VehicleTargetImageReady, (targetImageUploadId: number, targetNumber: number): void => {
            if (this.onVehicleTargetImageReady)
                this.onVehicleTargetImageReady(targetImageUploadId, targetNumber);
        });
        this.connection.on(SignalRSupport.TechnicianConnectedSession, (techName: string): void => {
            Toast.info("A technician has accepted the calibration session");
        });
        this.connection.on(SignalRSupport.TechnicianCameraRejected, (): void => {
            if (this.onTechnicianCameraRejected)
                this.onTechnicianCameraRejected();
        });
        this.connection.on(SignalRSupport.CalibrationCanceledByDevice, (): void => {
            Toast.warning("The calibration has been cancelled.");
            Global.MainApp.routerPush("/Canceled");
        });
        this.connection.on(SignalRSupport.CalibrationDisconnectedDevice, (): void => {
            Toast.warning("The device has disconnected and the calibration has been cancelled.");
            Global.MainApp.routerPush("/Canceled");
        });
        this.connection.on(SignalRSupport.DeviceOutOfSync, (): void => {
            Toast.error("The Auggie device is out of sync and can't respond to the current action. The session may need to be restarted.");
        });
        this.connection.on(SignalRSupport.DeviceFailure, (msg: string): void => {
            ToastFeedback.error(FeedbackFormat.Close, msg, "Auggie Device Failure");
        });
    }

    companionSessionStarted = false;

    isCompanionStarted(): boolean {
        return this.companionSessionStarted;
    }

    startCompanionSession(): boolean {
        if (!this.isConnected) return false;
        if (this.companionSessionStarted) return true;

        if (!Global.CompanionGuid || !Global.CalibrationSessionGuid) return false;

        this.connection.send("companionStartSession", Global.CompanionGuid, Global.CalibrationSessionGuid);
        this.companionSessionStarted = true;

        console.log("Companion Session started");

        return true;
    }

    onConnected(): void {
        console.log("SignalR connection started");
        this.isConnectedCache = true;
    }

    onConnectionError(error: Error | undefined): void {
        if (error && error.message)
            console.error(error.message);
        this.isConnectedCache = false;
        this.connectionCache = null;
        Toast.error("The server connection has been lost");
    }
}

export const SignalR = new SignalRSupport();